import Select from './select/select.tsx';
import { Controller } from 'react-hook-form';
import { GetOptionLabel } from 'react-select';
import makeAnimated from 'react-select/animated';

const SelectInput = ({
  control,
  options,
  name,
  rules,
  getOptionLabel,
  getOptionValue,
  disabled,
  isMulti,
  isClearable,
  isLoading,
  onChange,
  ...rest
}) => {
  const animatedComponents = makeAnimated();
  
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      {...rest}
      render={({ field }) => (
        <Select
          {...field}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isMulti={isMulti}
          isClearable={isClearable}
          isLoading={isLoading}
          options={options}
          isDisabled={false}
          components={animatedComponents}
          onChange={onChange}
          classNamePrefix={'select'}
        />
      )}
    />
  );
};

export default SelectInput;
