import {useState, useEffect, useRef, useCallback} from "react";

import '../../assets/css/tost.css';
import "animate.css";
import { cssTransition, toast } from 'react-toastify';
import { BiRupee } from "react-icons/bi";
import moment from 'moment';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

export const admin_phone = '+919730330783'

export const LIMIT = 9

export const LINK_EXPIRE = 3

export const register_fee = 5000
export const register_fee_hospital = 5000

export const register_valid = 6 //6=6 month
export const renew_per = 10 //reg fee will increase by 10% for renew
export const bookingamount = 50000;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const APP_URL = process.env.REACT_APP_URL
export const APP_NAME = process.env.REACT_APP_NAME
export const IMG_URL = process.env.REACT_APP_IMG_URL
export const RAZOR_KEY = process.env.REACT_RAZOR_KEY
export const RAZOR_JS = "https://checkout.razorpay.com/v1/checkout.js"
export const APP_LOGO = "https://i.imgur.com/3g7nmJC.jpg";

export const EXTERNAL_AUDIO = 'https://www.himlalpun.net/counselling/backend/audio/ringing-151670.mp3';
export const EXPERT_LABEL = 'Experts';
export const USER_LABEL = 'Users';

export const THEME = "#009f7f"

export const snackBarOptions = {
   position: 'bottom-right',
   style: {
     backgroundColor: '#0cb339',
     color: '#FFF',
     textAlign: 'center',
     fontSize:'16px'
   },
   closeStyle: {
     color: '#FFF',
     fontSize: '16px',
   },
}

export const bounce = cssTransition({
	enter: "animate__animated animate__bounceIn",
	exit: "animate__animated animate__bounceOut"
});

const tost_anim = cssTransition({
	enter: "swirl-in-fwd",
	exit: "swirl-out-bck"
});

export const toast_anim = (message) => {
   toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      transition: tost_anim,
      autoClose: 2000
   });
}
export const toast_anim_error = (message) => {
   toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 5000,
   });
}

export const replaceWithBr = (str) => {
   return str.replace(/\n/g, "<br />")
}

export const useStateCallback = (initialState) => {
   const [state, setState] = useState(initialState);
   const cbRef = useRef(null); // init mutable ref container for callbacks
 
   const setStateCallback = useCallback((state, cb) => {
     cbRef.current = cb; // store current, passed callback in ref
     setState(state);
   }, []); // keep object reference stable, exactly like `useState`
 
   useEffect(() => {
     // cb.current is `null` on initial render, 
     // so we only invoke callback on state *updates*
     if (cbRef.current) {
       cbRef.current(state);
       cbRef.current = null; // reset callback after execution
     }
   }, [state]);
 
   return [state, setStateCallback];
}

export const loadScript = (src) => {
   return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
         resolve(true);
      };
      script.onerror = () => {
         resolve(false);
      };
      document.body.appendChild(script);
  });
}

export const date_format = (date) => {
   return moment(date).format('DD MMMM, YYYY')
}

export const amount_format = (amt) => {
   //return amt.toLocaleString(undefined, { minimumFractionDigits: 2 })
   return <div className="flex items-center"><BiRupee />{Number(amt).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
}

export const amount_with_current = (amt) => {
   return <span className="flex items-center">{Number(amt).toLocaleString(undefined, { minimumFractionDigits: 0 })} coins</span>
}

export const gateway_amount = (amt) => {
   return Number(amt)*100;
}

export const random_str = (length) => {
   let len = Number(length) + 2;
   return (Math.random() + 1).toString(36).slice(2, len);
}

export const remove_overflow = () => {
   let cartDrawer = document.querySelector(".cart-drawer");
   let body = document.querySelector("body");
   cartDrawer.remove();
   body.classList.remove("overflow-hidden");
}

export const discount = (max, min) => {
   
   max = Number(max)
   min = Number(min)

   if(max <= 0 || min <= 0){
      return; 
   }
   
   let def = max - min;
   let disc = (def/max)*100
   disc = Math.round(disc);
   return disc
}

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

export const queryStringToJSON = (queryString) => {
   if(queryString.indexOf('?') > -1){
     queryString = queryString.split('?')[1];
   }
   var pairs = queryString.split('&');
   var result = {};
   pairs.forEach(function(pair) {
     pair = pair.split('=');
     result[pair[0]] = decodeURIComponent(pair[1] || '');
   });
   return result;
}

export const addDays = (date, days) => {

   return date.setDate(date.getDate() + parseInt(days));
   
};

export const showRating = (rating) => {
   let children = []
   //Inner loop to create children
   for (let j = 1; j <= 5; j++) {
      if(j <= rating){
            children.push(<AiFillStar size={25} className="text-yellow-400" />)
      }else{
            children.push(<AiOutlineStar size={25} color="#707070" />)
      }
   }
   return children
} 



export const machine_condition = {
   '1':'New',
   '2':'Refurbished',
   '3':'Used',
   '4':'Imported Refurbished',
};


export const consultation_for = [
   {
    'id':'Radiology',
    'label':'Radiology',
  },
  {
    'id':'Cardiology',
    'label':'Cardiology',
  },
  {
    'id':'Gynecology',
    'label':'Gynecology',
  },
  {
    'id':'Other',
    'label':'Other',
  }
];

export const accessoriesOptions = [
   {
    'id':'UPS 3kva',
    'label':'UPS 3kva',
  },
  {
    'id':'UPS 40kva',
    'label':'UPS 40kva',
  },
  {
    'id':'UPS 80kva',
    'label':'UPS 80kva',
  },
  {
    'id':'UPS 120kva',
    'label':'UPS 120kva',
  },
  {
    'id':'Single Head Injector',
    'label':'Dual Head Injector',
  },
  {
    'id':'Lead Glass',
    'label':'Lead Glass',
  },
  {
    'id':'Stablizer 20kva',
    'label':'Stablizer 20kva',
  },
  {
    'id':'Stablizer 40kva',
    'label':'Stablizer 40kva',
  },
  {
    'id':'Stablizer 80kva',
    'label':'Stablizer 80kva',
  },
  {
    'id':'Stablizer 120kva',
    'label':'Stablizer 120kva',
  },
   {
    'id':'Other',
    'label':'Other',
  }
];


   
 export const pngjpgonly = {'image/jpeg': [],
         'image/png': []
   };

export const pdfdoconly = {'application/pdf': [],
         'application/msword': []
   };


export const usageOptions = [
   {
    'id':'Tube Count',
    'label':'Tube Count',
  },
  {
    'id':'Helium Level',
    'label':'Helium Level',
  },
  {
    'id':'OT',
    'label':'OT',
  },
  {
    'id':'ICU',
    'label':'ICU',
  }
];


export const internalsysOptions = [
   {
    'id':'Tube Year',
    'label':'Tube Year',
  },
  {
    'id':'Magnet Year',
    'label':'Magnet Year',
  },
  {
    'id':'OT Medical Equipment Year',
    'label':'OT Medical Equipment Year',
  },
  {
    'id':'ICU Medical Equipment Year',
    'label':'ICU Medical Equipment Year',
  }
];

//in fun components call after state change example
/*
(ups3kva,4-kva,80kva,120kva & injector (cathlab/ct scan),lead glass,stablizer(20kva,40kva,80,120))

setQuantity(
   props.quantity,
   (s) => {
      setInCart(s <= 0 ? false : true)
      setQuantity(s)
   }  
) */


export const specalizationOptions = [
   {
    'id':1,
    'label':'Depression',
  },
  {
    'id':2,
    'label':'Home Violence',
  },
  {
   'id':3,
   'label':"Divorce"
  },
   {
   'id':4,
   'label':"Addiction"
  },
    {
   'id':5,
   'label':"Family Issues"
  },
    {
   'id':6,
   'label':"Breakups"
  }
];

export const languageOptions = [
   {
    'id':1,
    'label':'English',
  },
  {
    'id':2,
    'label':'Hindi',
  },
  {
   'id':3,
   'label':"Marathi"
  },
   {
   'id':4,
   'label':"Gujarati"
  },
    {
   'id':5,
   'label':"Punjabi"
  },
    {
   'id':6,
   'label':"Tamil"
  }
];

export const dnyreasonOptions = [
   {
    'label':'Busy With Other Appointment',
  },
  {
   'label':'No available for date',
  },
  {
   'label':'Reason Not in the list',
  }
];


export const myPrettyDateFormat =(d) => {
    const pureDateStr = d.toString().split('GMT')[0];
    // const timeStr = `${d.getUTCHours()}:${d.getMinutes()}`; alert(timeStr)
    return `${pureDateStr}`
  }

export const weekdays = [
   {
    'label':'Sunday',
  },
  {
   'label':'Monday',
  },
  {
   'label':'Tuesday',
  },
   {
   'label':'Wednesday',
  },
   {
   'label':'Thrusday',
  },
   {
   'label':'Friday',
  },
   {
   'label':'Saturday',
  }
];

export const ratings = [
   {
    'name':'Rashami',
    'review':'I love to talk and when I moved cities, there was no one to talk to and that did not work well with me. But thanks to now&me, I can talk as much as I want to and be me again.',
    'rating':5,
  },
  {
    'name':'Jagdish',
    'review':'Very humble and he gave me support when i needed it most.',
    'rating':5,
  },
   {
    'name':'Kamlesh',
    'review':'Generally therapy sessions take place once or twice a week. But I am happy that I found a place that is so therapeutic and I can visit here everyday',
    'rating':5,
  },   
];
