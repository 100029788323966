import React, {useState, useEffect , useRef} from "react";
import Header from 'layouts/Header'
import Footer from 'layouts/Footer'
import Logo from 'layouts/logo';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {EyeOff, Eye, Loading} from "components/custom-icons";
import Button from 'components/ui/Button.tsx'
import Input from 'components/ui/input.jsx'

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';

import axios from 'axios';  
import { yupResolver } from '@hookform/resolvers/yup';
import {API_ENDPOINT,toast_anim , toast_anim_error} from 'components/config/Common';
import {firebase , auth, authentication} from 'components/config/FirebaseConfig';
import PasswordInput from 'components/ui/password-input.tsx'

import {useAuth} from 'contexts/provider/AuthProvider'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {Links} from 'routes/PathLink'
import ModalView from '_utils/ModalView'
import {deviceDetect} from "react-device-detect"
import { produce } from 'immer';
import { useDoctorReg } from "contexts/doctorregistration/doctor-reg";
import SocialLogin from "components/auth/SocialLogin";
import OtpInput from "react-otp-input";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
const SignupSchema = Yup.object().shape({
	 phone_no: Yup.string()
      .required('You must need to provide phone number.'),
});

const defaultValues = {  
   phone_no: '',
 };

const LoginwithPhone = (props) => {
   const [show, setshow] = useState(false);
   const [loading, setLoading] = useState(false);
   const {form,setForm} = useDoctorReg();

   const { login } = useAuth();
   let navigate = useNavigate();
   const [isOpen, setIsOpen] = useState(false)
   const [otp, setotp] = useState('');
  const [final, setfinal] = useState('');
  const [whereto, setWhereto] = useState('');
  const [phone_no, setPhone_no] = useState(false);
  const [userData,setUserData] = useState([]);
      const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
      shouldFocusError:false,
   });
const refphone = useRef(null);
   useEffect(() => {
     
      if(localStorage.getItem('access_token')){
         return navigate("/", { replace: true });
      }
   })

   const handleView = () => {
		setIsOpen(true)
	}

   const ValidateOtp = () =>{
       if (otp === null || final === null)
            return;
        final.confirm(otp).then((result) => {
         
            console.log("USER DATA",userData);
            let input = [];
            input = {...input,phone_no:phone_no }
            input = {...input, device:deviceDetect()}

            fetch(`${API_ENDPOINT}/expert_login_phone`,{
               method: 'POST',
               headers : {        
                  'Content-Type': 'application/json',         
               },
               body: JSON.stringify(input)
            }).then(function (response) {
                  return response.json();
            }).then( (result)=> {
               //console.log(result)
               setLoading(false)
               if(result.status === 1){
                  localStorage.setItem('access_token',result.access_token);
                  localStorage.setItem('user_type',result.user_type);
                  localStorage.setItem('user_id',result.user_id);
                  localStorage.setItem('user_name',result.user_name);
                  //props.setLogin()
                  login({access_token:result.access_token, user_name:result.user_name})
                   setTimeout(() => { 
                       return navigate(Links.DOCTORDASHBOARD, { replace: true });
                      }, 2000);
                 
               }
               if(result.status === 0){
                  //setErrors({password:result.message})
               }
               //setErrors({phone_no:result.message})
            });
            // success
       
        }).catch((err) => {
            alert("Wrong code");
        })
  
   }

   const closeModal = () => {
      setIsOpen(false)      
   }

    const loginsubmit = async (values) => {
       //props.setActivestepfun(2);
       let input ={
                  phone_no:values?.phone_no,
                  from:'phoneno',
                  password:values?.password,
                  phone_no_without_sign:values?.phone_no,
                } ;
       values = input;
       setLoading(true);
       setPhone_no(values?.phone_no);
       axios
      .post(API_ENDPOINT+'/check_expert_phoneno_exist', values)
      .then((response) => {
         //console.log("RESPONSE",response.data);

         if(response.data?.status == 1){
           let input = [];
            input = {...input,phone_no:values?.phone_no_without_sign , password:values?.password }
            input = {...input, device:deviceDetect()}

            fetch(`${API_ENDPOINT}/expert_login_phone`,{
               method: 'POST',
               headers : {        
                  'Content-Type': 'application/json',         
               },
               body: JSON.stringify(input)
            }).then(function (response) {
                  return response.json();
            }).then( (result)=> {
               //console.log(result)
               setLoading(false)
               if(result.status === 1){
                  localStorage.setItem('access_token',result.access_token);
                  localStorage.setItem('user_type',result.user_type);
                  localStorage.setItem('user_id',result.user_id);
                  localStorage.setItem('user_name',result.user_name);
                  //props.setLogin()
                  login({access_token:result.access_token, user_name:result.user_name})
                   setTimeout(() => { 
                       return navigate(Links.DOCTORDASHBOARD, { replace: true });
                      }, 2000);
                 
               }
               if(result.status === 0){
                  toast_anim_error(result?.message);
                  //setErrors({password:result.message})
               }
               //setErrors({phone_no:result.message})
            });
          
         }else{
             setLoading(false);
           toast_anim_error("Your are not registered with us yet , please Sign up Now.")
         }
         

        //setPost(response.data);
      });


       return false;
   }

   
   return( <div className="flex justify-center">
            <div className="flex h-full  w-screen flex-col justify-center bg-light  px-5  md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl my-5">
                 
                  <>
                  {/* <div className="flex justify-center">
                  <h1 className="text-xl font-black text-accent">Login</h1>
                  </div> */}      
                  <form onSubmit={handleSubmit(loginsubmit)}>  
                  {/*<p className="mt-1 mb-8 text-center text-sm text-body  md:text-base">
                     Login with your Phone number and OTP
                  </p>*/}
                   
                       <div className="mb-5 w-full flex flex-col">
                           <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                           <Controller
                              name="phone_no"
                              control={control}
                              ref={refphone}
                              render={({ field }) => (
                                 <PhoneInput
                                    country="in"
                                    disableDropdown
                                    countryCodeEditable={false}
                                    inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                                    dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                                    {...field}
                                 />
                              )}
                           />
                           {errors.phone_no?.message && 
                           <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>

                           }
                        </div>
                         <div className="mb-2 w-full flex flex-col">
                      <PasswordInput                
                        label={`Password`}
                           {...register('password')}
                           variant="outline"
                           className="mb-5"                        
                           type='password'
                           error={errors.password?.message}
                           required
                        />
                        {errors.password?.message && 
                           <p className="my-2 text-xs text-start text-red-500">{errors.password?.message}</p>
                           }
                        </div>
                     <div className="mt-8 flex justify-center">
                        {
                        !loading
                        ?
                        <Button className="w-full" >Login</Button>
                        :
                        <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600" />
                        }
                     </div>
               </form>
               
               </>
             
            </div>
         </div>)
}

export default LoginwithPhone