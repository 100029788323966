import React, {useState, useEffect} from "react";
import Logo from './logo';
import { Link } from "react-router-dom";
import AuthorizedMenu from './AuthorizedMenu'
import MobileNav from './mobile-nav';
import { useNavigate } from "react-router-dom";

import { Drawer } from "flowbite";
import axios from 'axios';  


//import {APP_URL} from '../config/Common'
import {useAuth} from '../contexts/provider/AuthProvider'
import { BsFillTelephoneFill } from "react-icons/bs";
import {admin_phone,EXPERT_LABEL , API_ENDPOINT} from '../components/config/Common'
import {Links} from '../routes/PathLink'
import ModalView from '../_utils/ModalView'
import Search from 'components/ui/search/search.tsx'
import { ChevronRightIcon } from '@heroicons/react/solid'
import PusherRequest from "components/calling/PusherRequest"
import PusherRequestExpert from "components/calling/PusherRequestExpert";
import ExpertStatus from "components/experts/ExpertStatus";
import Notifcationbadge from 'components/ui/Notifcationbadge'
import NotifcationAppointmentbadge from 'components/ui/NotifcationAppointmentbadge';
import { AiFillTwitterCircle, AiFillInstagram, AiFillFacebook, AiFillLinkedin } from 'react-icons/ai';

const Header = (props) => {
    const {hidesearch ,hidebanner } = props;
    const [access_token,setAccess_token] = useState(localStorage.getItem('access_token'));
    const [user_type,setUser_type] = useState(localStorage.getItem('user_type'));

    const { logout, isUser } = useAuth();
    const [openLogin, setOpenLogin] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [openLoginNav, setOpenLoginNav] = useState(false)
    const [callNowModal, setCallNowModal] = useState(false)
    const [authModelView, setAuthModelView] = useState('LoginView')
    const [totalamount,setTotalamount] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        //console.log('props===========',props)
        if(access_token){
            setIsLogin(true)
            if(user_type == 'expert'){
                getWalletTotal()
            }
            
        }
    }, [access_token])

    const openLoginModal = (loginView) => {        
        setOpenLogin(!openLogin)
        setAuthModelView(loginView)
        setOpenLoginNav(false)
    }
    const setLogin = () => {
        setIsLogin(true)
        openLoginModal(authModelView)
        //console.log('paren===========')
        if(props.rerenderParentCallback){
            props.rerenderParentCallback();
        }
    }

        const getWalletTotal = () => {
        console.log(access_token)
        fetch(`${API_ENDPOINT}/expert_total_wallet_amount`,{
            method: 'GET',
            headers : {        
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer '+access_token, 
            }
        }).then(function (response) {
                return response.json();
        }).then( (result)=> {
            console.log("TOTAL WALLET AMOUNT ",result)
            if(result.status == 1){
                setTotalamount(result.data);
            }
        })
    }   

    const logoutUser = () => {
        
        let user_name = localStorage.getItem("user_name")
        let user_type = localStorage.getItem("user_type")

        console.log("usertype",user_type);
        if(user_type == 'expert'){
            changeStatus();
            // update expert status 
        }

        localStorage.removeItem("access_token")
        localStorage.removeItem("user_type")
        localStorage.removeItem("user_id")
        localStorage.removeItem("user_name")

        //sessionStorage.clear()
       
        logout()
        setIsLogin(false)
        navigate(
            Links.LOGOUT,
            {
               state: { user_name: user_name },
               replace: true 
            },
            
        );
    }
    const cart_count = (count) => {
        //setCartCount(count)
    }

    const changeStatus = () =>{

    let input = {
      status:'offline',
    }
    axios.post(`${API_ENDPOINT}/set_availability_status`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token,
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         //setLoading(false)
         console.log('data=====',result)
      if(result.data.status == 1){
        //toast_anim(result.data.message);
        //props.closeCalling();
      }else if(result.data.status == 0){
        //toast_anim_error(result.data.message);
      }
      })

  }

    const openDrawer = () => {
        const options = {
            placement: 'right',
            backdrop: true,
            bodyScrolling: false,
            edge: false,
            edgeOffset: '',
            backdropClasses: 'cart-drawer bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
            onHide: () => {
                //console.log('drawer is hidden');
                setDrawerVisible(false)
            },
            onShow: () => {
                //console.log('drawer is shown');
                setDrawerVisible(true)
            },
            onToggle: () => {
                //console.log('drawer has been toggled');
            }
          };
        const $targetEl = document.getElementById('drawer-right-example');
        const drawer: DrawerInterface = new Drawer($targetEl, options);
        drawer.show();
    }
    
    const closeDrawer = () => {
        //console.log('close drawer')
        const options1 = {
            placement: 'right',
            backdrop: false,
            bodyScrolling: true,
            edge: false,
            edgeOffset: '',
            backdropClasses: 'bg-gray-900 bg-opacity-0 dark:bg-opacity-0 fixed',
          };
        const $targetEl = document.getElementById('drawer-right-example');
        const drawer: DrawerInterface = new Drawer($targetEl, options1);
        drawer.hide();
        let cartDrawer = document.querySelector(".cart-drawer");
        let body = document.querySelector("body");
        cartDrawer.remove();
        body.classList.remove("overflow-hidden");
    }

    
    const closeLoginNav = () => {
        setOpenLoginNav(false)
        setCallNowModal(false)
    }

    const openLoginNavModal = () => {
        //setOpenLoginNav(true)
    }
    const openCallNowModal = () => {
        setCallNowModal(true)
    }

    const openDoctorsLink = (e) =>{
        e.preventDefault();
        console.log("LCIKC open doctor Link",access_token);
      if(access_token && access_token !== null ){
        console.log("IN FI",access_token);
         navigate(Links.EXPERTS);
      }else{
         console.log("IN ESLE",access_token);
        navigate(Links.USER_LOGIN, {
               replace: true 
            },);
      }
    } 
    

    const openLoginPage = () =>{
        navigate(Links.CHOOSE_ACCOUNT_TYPE, {
               replace: true 
            },);
    }

    return (
        <>
        <header className={'site-header-with-search h-14 md:h-16 lg:h-28 hidden md:block'}>
            <div
                className={'flex w-full transform-gpu items-center justify-between border-b border-border-200 bg-light px-4 py-2 shadow-sm transition-transform duration-300 lg:px-8 dark:bg-gray-800 dark:border-gray-600'}
             >
                <div className="flex w-full items-center lg:w-auto">
                    <Logo className={'ltr:ml-0 rtl:mr-0 text-accent'} />
                </div>
              
                <ul className="hidden shrink-0 items-center space-x-7 rtl:space-x-reverse lg:flex 2xl:space-x-10">
                    <li>
                        {/*<Search
                        variant={'minimal'}
                        className="pl-10"  />
                        */}
                    </li>
                    {user_type && user_type != 'expert' && 
                    <li>
                        <Link
                            to=''
                            onClick={openDoctorsLink}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-accent hover:text-secondary-color focus:text-secondary-color dark:text-zinc-400"
                        >Find an Expert</Link>
                    </li>
                    }
                     <li>
                        <Link
                            to={Links.HOWITWORK}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-accent hover:text-secondary-color focus:text-secondary-color dark:text-zinc-400"
                        >How it works</Link>
                    </li>
                    <li>
                        <Link
                            to={Links.BLOG}
                            target="_blank"
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-accent hover:text-secondary-color focus:text-secondary-color dark:text-zinc-400"
                        >Blog</Link>
                    </li>
                    {!isUser ?
                    <li>
                        <Link
                            to={Links.BECOMEANEXPERT}
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-accent hover:text-secondary-color focus:text-secondary-color dark:text-zinc-400"
                        >Become An Expert</Link>
                    </li>
                    :
                    <></>
                    }
                    {user_type && user_type == 'expert' && 
                    <li>
                        <Link
                            to={Links.MYDOCTORWALLET}
                            
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-accent hover:text-secondary-color focus:text-secondary-color dark:text-zinc-400"
                        ><Notifcationbadge max={10} min={100} totalamount={totalamount}/></Link>
                    </li>
                    }
                     {user_type && user_type == 'expert' && 
                     <li>
                        <Link
                            to={Links.DOCTORDASHBOARD}
                            
                            className="flex items-center font-normal text-heading no-underline transition duration-200 text-accent hover:text-secondary-color focus:text-secondary-color dark:text-zinc-400"
                        ><NotifcationAppointmentbadge  max={10} min={100}/></Link>
                    </li>
                    
                    }

                   {
                      isUser ?
                      <div className="flex ">
                        <a href="https://www.facebook.com/profile.php?id=61567345235104" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#1877f2]"><AiFillFacebook /></a>
                        <a href="https://x.com/healthlogist1" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#1da1f2]"><AiFillTwitterCircle /></a>
                        <a href="https://www.instagram.com/health.logist" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#c13584]"><AiFillInstagram /></a>
                    </div>
                    :
                    null
                    }
                    
                    <div className="flex items-center space-x-4 rtl:space-x-reverse">
                        {/* <a href="#" target="_blank" rel="noreferrer" className="inline-flex h-9 shrink-0 items-center justify-center rounded border border-transparent bg-accent px-3 py-0 text-sm font-semibold leading-none text-light outline-none transition duration-300 ease-in-out hover:bg-accent-hover focus:shadow focus:outline-none focus:ring-1 focus:ring-accent-700">Become a Seller</a> */}
                        
                        <li>
                        {
                        !isUser ?
                            <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-3 py-0 h-9 text-sm font-semibold " onClick={openLoginPage}>Login</button>
                        :
                        <AuthorizedMenu logout={logoutUser}  />
                        }
                        </li>
                    </div>
                      {
                      !isUser ?
                      <div className="flex ">
                        <a href="https://www.facebook.com/profile.php?id=61567345235104" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#1877f2]"><AiFillFacebook /></a>
                        <a href="https://x.com/healthlogist1" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#1da1f2]"><AiFillTwitterCircle /></a>
                        <a href="https://www.instagram.com/health.logist" rel="noreferrer" target="_blank" className="mr-2 text-3xl text-accent hover:text-[#c13584]"><AiFillInstagram /></a>
                    </div>
                    :
                    null
                    }
                </ul>
            </div>
        </header>
        
        <MobileNav openLogin={openLoginNavModal} drawerVisible={openDrawer} />
        {hidesearch || user_type == 'expert'?
        (null)
        :(<Search
          variant={'minimal'}
          className=""  />)
        }
        <ModalView open={openLoginNav} onClose={closeLoginNav}>
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[500px] md:rounded-xl">
                <div className="flex items-center justify-around flex-wrap">
                    <div className="p-2 mt-8 lg:p-0">
                        <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-5 text-xl " onClick={() => navigate(Links.PATIENT_LOGIN)}>Patient Login</button>
                    </div>
                     <div className="p-2 mt-8 lg:p-0">
                        <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-5 text-xl " onClick={() => navigate(Links.DOCTOR_SIGNUP)}>Doctor Login</button>
                    </div>
                </div>
            </div>
        </ModalView>
        {user_type == 'user' &&
            <PusherRequest />
        }
        
        {user_type == 'expert' &&
         <PusherRequestExpert />
        }
         {user_type == 'expert' &&
            <ExpertStatus />
        }
        </>
    )
}


export default Header;