import React, {useState, useEffect} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from 'components/config/Common'

import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Loading} from 'components/custom-icons'
import Seo from 'components/seo/seo'

const About = (props) => {
   const [loading, setLoading] = useState(false)
   const [details, setDetails] = useState(null)
   const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')

   useEffect(() => {
      getPageDetails()
   },[]);

   const getPageDetails = () => {
      setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/about`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('slider======',result)
         setLoading(false)
			setDetails(result.data)
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
			setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
		})
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}/about`}
         image={`${IMG_URL}${details?.image}`}
      />
      <Header hidesearch="true" />
      {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <>
         <section className="mx-auto w-full max-w-1920 bg-light py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20 bg-no-repeat bg-cover" style={{backgroundImage: "linear-gradient(45deg,rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url(./img/bg-search.jpg)"}} >
         
            <h1 className="mb-4 text-heading text-white sm:mb-5 text-center">
            {details?.title}
            </h1>
            <div className="mx-auto leading-loose text-white lg:w-[55%] text-center">
               <p>At HealthLogist, we provide an online counseling and emotional support platform designed to foster mental wellness. It anonymously connects you with the right expert from our panel consisting of psychologists, psychotherapists, counselors, life coaches and career coaches, who understand you and guide you through completely confidential individual sessions.</p>
               <p className="pt-5">The HealthLogist strives to build a comprehensive mental health ecosystem, offering tailored treatment and care plans for various conditions of mental health.</p>
            </div>
         </section>
         <section className="mx-auto w-full bg-light py-10 px-4 lg:px-8 xl:px-16 2xl:px-20">
            <div className="mx-auto w-[80%] bg-accent-200 text-center text-body-dark text-3xl px-16 py-10 rounded-lg leading-normal">
               It's okay to not be okay. Just remember, you're not alone, and help is always available.
            </div>
         </section>
         <section className="mx-auto w-full text-white text-sm text-center max-w-1920 bg-orange-500 py-5 px-4 lg:px-8 xl:px-16 2xl:px-20">
            <div>Disclaimer: We are not a medical service or suicide prevention helpline. If you are feeling suicidal, we would suggest you immediately call up a suicide prevention helpline.</div>
         </section>
         </>
      }
      <Footer />
      </ScrollPageTop>
   )
}

export default About