import React, {useState, useEffect ,useRef } from "react";
import ScrollPageTop from 'components/ui/ScrollPageTop'
import { Link, useNavigate } from "react-router-dom";
import { DoctorRegProvider } from "contexts/doctorregistration/doctor-reg";
import { Tab } from '@headlessui/react'

import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from 'routes/PathLink'
import {
   API_ENDPOINT,
   APP_URL, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
} from 'components/config/Common'
import Input from 'components/ui/input.jsx'

import 'react-phone-input-2/lib/bootstrap.css'
import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'
import Label from 'components/ui/label.tsx'
import {deviceDetect} from "react-device-detect";
//import MultiStep from 'react-multistep'
import PatientRegisterMain from "components/auth/user/PatientRegisterMain";
import PatientRegisterBasic from "components/auth/user/PatientRegisterBasic";
import Logo from "layouts/logo";
import SigninwithPhone from "components/auth/user/SigninwithPhone";
import SignupwithEmail from "components/auth/user/SignupwithEmail";
import SocialLogin from "components/auth/SocialLogin";
const UserRegister = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [selectedIndex, setSelectedIndex] = useState(0)
   const [step , setStep] = useState(2);


   const { login } = useAuth();
   
    const setActivestepfun = (val) => {
      console.log("Parent FUNCtion",val);
      setStep(val);
   }

    const Pagedisplay = () =>{
      console.log("Step",step);
      switch (step) {
        case 1: 
          return (
            <PatientRegisterMain setActivestepfun={setActivestepfun} />
          )
        case 2: 
          return (
            <PatientRegisterBasic setActivestepfun={setActivestepfun} />
          )
        default: 
           // do nothing
      }
   }

   return(
      <ScrollPageTop>
         
         <DoctorRegProvider>
         <div className="flex bg-white align-center">
            <div className="w-1/2 bg-cover bg-center min-h-screen hidden md:block" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${APP_URL}/img/userbg-reglog.jpg)`,backgroundSize:'cover',}} >
               <div className="px-4 py-3">
               <div className="flex w-full items-center lg:w-auto">
                    <Logo className={'ltr:ml-0 rtl:mr-0 text-white px-2 py-2 '} />
                </div>
                <h2 className="text-5xl text-white flex justify-center align-center mt-10">
                Talk to Top Therapists and Mental Health Experts
                </h2>
                <p className=" text-white flex justify-center align-center mt-10">
                  Sometimes all you need to feel better is someone who really understands. Connect with our experts for 24/7 professional help at an affordable price.
                </p>
                </div>
            </div>
            <div className="flex h-full min-h-screen w-full flex-col justify-center bg-light py-6 px-5  md:h-auto md:min-h-0 md:w-1/2 ">
                <div className="flex w-full items-center justify-center lg:w-auto block md:hidden">
                    <Logo className={'text-accent px-2 py-2 '} />
                </div>
               
               <>
               {step ?
                  (<div className="formbody">
                  {Pagedisplay()}
                  </div>):
                 null
               }
               </>
              
            </div>
         </div>
         <div id="recaptcha-container"></div>

       

         </DoctorRegProvider>     
      </ScrollPageTop>
   )
}

export default UserRegister