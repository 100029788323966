import React, {useState, useEffect ,useRef} from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from 'routes/PathLink'
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
} from 'components/config/Common'
import Input from 'components/ui/input.jsx'
import FileInput from 'components/ui/file-input.jsx';

import PasswordInput from 'components/ui/password-input.tsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'
import SelectInput from 'components/ui/select-input.jsx'
import Label from 'components/ui/label.tsx'
import Radio from 'components/ui/radio/radio.tsx';
import Checkbox from 'components/ui/checkbox/checkbox.tsx'
import {deviceDetect} from "react-device-detect"
import { produce } from 'immer';
import { useDoctorReg } from "contexts/doctorregistration/doctor-reg";
import {firebase , auth, authentication} from 'components/config/FirebaseConfig';


//import SocialLogin from './SocialLogin'

const SignupSchema = Yup.object().shape({
   name: Yup.string()
      .required('You must need to provide your  name.')
      .min(2, 'Name is too short!')
      .max(50, 'Name is too long!'),
   gender: Yup.string()
    .required("Please choose gender it is Required"),
    email: Yup.string()
      .required('You must need to provide your email address.')
      .email('Invalid email.'),
    password: Yup.string().required('Password is required'),
});

const defaultValues = {  
   name: '',
   email:'',
   phone_no:'',
   password:'',
   agree_to_pay:true
 };

const PatientRegisterBasic = (props) => {
   //const {setActivestepfun} = props;
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const {form,setForm} = useDoctorReg();
   const [otp, setotp] = useState('');
   const [show, setshow] = useState(false);
	const { login } = useAuth();
   const refphone = useRef(null);
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
      shouldFocusError:false,
   });

   const { phone_no } = props;
   const [final, setfinal] = useState('');

   useEffect(() => {
      if(localStorage.getItem('access_token')){
         return navigate(Links.HOME, { replace: true });
      }
   })

   useEffect(() => {
      reset(defaultValues)
   },[])

   // Validate OTP
   console.log("FROM FROM PREVIOUS",form);
   const dealerSubscription = async (values) => {
       //props.setActivestepfun(2);
       //console.log(values)
      //let input = values;
      //input = {...input,phone_no:phone_no}
      // input = [...input,phone_no:phone_no];
       //setForm(values)
       setForm(
          produce((formState) => {
            formState.steps.basic = {
              values,
              valid: true,
              dirty: false,
            };
          }));
       console.log("form Data ",form);

       let fromdata = {
         'email': values?.email,
         'phone_no':'+'+values?.phone_no,
         'name':values?.name,
         'gender':values?.gender,
         'password':values?.password,
        } 

        console.log('fromdata====',fromdata)
      
       axios
      .post(API_ENDPOINT+'/user_register', fromdata)
      .then((response) => {
         console.log("RESPONSE",response.data);
         if(response.data?.status == 1){
             localStorage.setItem('access_token',response.data.access_token);
             localStorage.setItem('user_type',response.data.user_type);
             localStorage.setItem('user_id',response.data.user_id);
             localStorage.setItem('user_name',response.data.user);
              login({access_token:response.data.access_token, user_name:response.data.user_name})
             navigate(Links.MYDASHBOARD, { replace: true });
         }
         //navigate(Links.REGISTRATIONSUCCESS, { replace: true });
         
        //setPost(response.data);
      });

       return false;
   }


   const onSubmit = async (values) => {
      console.log('on submit======',values)
       props.setActivestepfun(2);
      return false;
   }

   return(
      <>
         <div className="flex justify-center">
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[580px] md:rounded-xl my-5">
               
            <> 
                    <div className="flex justify-center">
                     <h1 className="font-light text-heading text-center">Sign up</h1>
                  </div>   
                  <div className="text-center text-sm text-body sm:text-base">Already have an user account? <Link to={`${Links.USER_LOGIN}`} className="text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1">Login</Link></div>
                  <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mb-5"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>
               <form onSubmit={handleSubmit(dealerSubscription)}>
                  <Input                
                     label={`Full name`}
                     {...register('name')}
                     variant="outline"
                     className="mb-5"
                     error={errors.name?.message}
                     required
                  /> 
                  
                  <div className="mb-5">
                       <Input                
                           label={`Email`}
                           {...register('email')}
                           variant="outline"
                           className="mb-5"
                           type="email"
                           error={errors.email?.message}
                           required
                        />
                  </div>   
                  
                  <div className="mb-5 w-full flex flex-col">
                        <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                        <Controller
                           name="phone_no"
                           control={control}
                           ref={refphone}
                           render={({ field }) => (
                              <PhoneInput
                                 country="in"
                                 disableDropdown
                                 countryCodeEditable={false}
                                 inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                                 dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                                 {...field}
                              />
                           )}
                        />
                        <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
                     </div>
                  
                                
                  <div className="mb-5">
                  <label for="name" class="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Gender<span class="text-red-500"> *</span></label>
                  <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                          <div class="flex items-center pl-3">
                              <input id="horizontal-list-radio-license" type="radio" value="male"  {...register('gender')} name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Male </label>
                          </div>
                      </li>
                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                          <div class="flex items-center pl-3">
                              <input id="horizontal-list-radio-id" type="radio" value="female"  {...register('gender')} name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Female</label>
                          </div>
                      </li>
                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                          <div class="flex items-center pl-3">
                              <input id="horizontal-list-radio-millitary" type="radio" value="non-binary"  {...register('gender')} name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Non Binary</label>
                          </div>
                      </li>
                  </ul>
                  </div>
                  <div className="mb-2 w-full flex flex-col">
                  <PasswordInput                
                     label={`Password`}
                     {...register('password')}
                     variant="outline"
                     className="mb-5"                        
                     type='password'
                     error={errors.password?.message}
                     required
                  />
                  </div>
                    <div className="mb-5 w-full flex flex-col">
                  <PasswordInput                
                     label={`Confirm Password`}
                     {...register('confirm_password')}
                     variant="outline"
                     className="mb-5"                        
                     type='password'
                     error={errors.confirm_password?.message}
                     required
                  /></div>
                  <div>
                     <Button 
                        className="w-full"
                        loading={loading}
                     >
                    Sign Up
                     </Button>
                  </div>
               </form>
               <p className="mt-4 mb-7 px-2 text-center text-sm leading-relaxed text-body sm:mt-5 sm:mb-10 sm:px-0 md:text-base">By signing up, you agree to our<Link to={Links.TERMS} className="mx-1 cursor-pointer text-accent underline hover:no-underline">terms</Link>&amp;<Link to={Links.PRIVACY} className="cursor-pointer text-accent underline hover:no-underline ltr:ml-1 rtl:mr-1">policy</Link></p>
               {/*<SocialLogin mode='Register' user_type='dealer' /> 
               <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>
               <div className="text-center text-sm text-body sm:text-base">Already have an account? <Link to={`${Links.PATIENT_LOGIN}`} className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1">Login</Link></div>
               */}
               </>
               
            </div>
         </div>
      </>
   )
}

export default PatientRegisterBasic