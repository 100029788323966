import {Links} from '../routes/PathLink'

export const patient_links = [
     Links.MYDASHBOARD,
     Links.EXPERTS,
     Links.MYWALLET,
     Links.MYCALLSESSION,
     Links.PROFILE,
];

export const doctor_links = [
];

export const customer_reject_links = [
    Links.ADDMONEYTOWALLET,
    Links.MYWALLET,
    Links.PROFILE,
];

export const expert_links = [
    Links.DOCTORDASHBOARD,
    Links.EXPERTAVALIBILITY,
    Links.EXPERTCALLSESSION,
    Links.DOCTORMYRATINGS,
    Links.MYDOCTORWALLET,
    Links.ACCEPTCALL,
    Links.PROFILE_DOCTOR,
];
