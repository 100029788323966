import React, {useState, useEffect} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from 'components/config/Common'
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import {Loading} from 'components/custom-icons'
import Seo from 'components/seo/seo'

const Terms = (props) => {
   
   const [loading, setLoading] = useState(false)
   const [details, setDetails] = useState(null)
   const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')

   useEffect(() => {
      getPageDetails()
   },[]);

   const getPageDetails = () => {
      setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/terms-and-conditions`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('slider======',result)
         setLoading(false)
			setDetails(result.data)
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
			setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
		})
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}/about`}
         image={`${IMG_URL}${details?.image}`}
      />
      <Header hidesearch="true"/>
      {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <section className="unsetul mx-auto w-full max-w-1920 bg-light py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
            <h1 className="mb-4 text-heading sm:mb-5 ">
            {details?.title}
            </h1>
            {/*{details?.image ?
                <div className="flex items-center justify-center mb-5">
                  <img src={`${IMG_URL}${details?.image}`} />
               </div>
               :
               null
            }
            
            {<div className="leading-loose text-body-dark" dangerouslySetInnerHTML={{__html:details?.content}} />}*/}
            <div className="leading-loose text-body-dark">
               HealthLogist is a psychological wellness platform offering emotional wellness services to individuals and organizations. These services include corporate wellness programs that provide employees access to various wellness tools. HealthLogist’s offerings may include:
               <ul>
    <li>Online and in-person consultations with expert Psychological Wellness Professionals authorized by HealthLogist to use the platform.</li>
    <li>Regular self-assessments and psychological evaluations.</li>
    <li>Workshops and webinars led by trained Psychological Wellness Professionals.</li>
    <li>Self-help resources, programs, and content available through websites, mobile apps, and emails.</li>
    <li>Encrypted private messaging with Psychological Wellness Professionals alongside online consultations.</li>
    <li>Engagement in user forums, discussions, feedback, blogs, and other platform contributions.</li>
            </ul>
            <p>The services provided by HealthLogist adhere to strict client anonymity and confidentiality policies (refer to our Privacy Policy). </p>
            <p>HealthLogist reserves the right to modify, add, or remove services from its offerings without prior notice.</p>

            </div>
            <h2 className="my-4 text-heading sm:my-5 ">
            Disclaimer
            </h2>
            <div className="leading-loose text-body-dark">
               <p>HealthLogist does not handle medical or psychological emergencies. We are not equipped to provide support in crisis situations, such as when someone is experiencing thoughts of self-harm, suicide, or severe clinical conditions like schizophrenia or other psychotic disorders. In such cases, in-person medical intervention is the most suitable form of assistance.</p>

<p className="pt-2">If you are facing any of these difficulties, contemplating suicide, or feel that you may be a danger to yourself or others, we strongly encourage you to seek immediate help at the nearest hospital or emergency room, where you can connect with a psychiatrist, social worker, counselor, or therapist. The same guidance applies in the event of any medical or psychological emergency. We also recommend involving a close family member or friend who can provide support during this time.</p>
            </div>
            <h2 className="my-4 text-heading sm:my-5 ">
            Intellectual Property
            </h2>
            <div className="leading-loose text-body-dark">
            The content on the HealthLogist platform is for informational purposes only. HealthLogist disclaims all liability for any loss or damage resulting from reliance on any part of the platform. Third-party content appearing on the platform is the property of their respective owners, and HealthLogist does not claim any rights over such content. This third-party material is used in accordance with the fair use provisions of Indian copyright law and applicable laws in other jurisdictions. HealthLogist retains copyright ownership over all other content on the platform. No information, content, or material from the platform or services may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed without express written permission from HealthLogist.
            </div>
            <h2 className="my-4 text-heading sm:my-5 ">
            Terms of use
            </h2>
            <div className="leading-loose text-body-dark">
               By agreeing to this User Agreement, you (the user) expressly acknowledge, understand, and agree to the following terms of use:
               <ul>
               <li>You are at least 18 years old and legally able to enter into a contract under the Indian Contract Act, 1872, and your use of the platform complies with all applicable laws and regulations.</li>
<li>All registration information you provide, either directly or through your organization, is accurate and truthful, and you agree to keep it up to date.</li>
<li>Your use of the platform is for personal, non-commercial purposes only. Any other use of the platform or its content is prohibited.</li>
<li>Your personal and non-commercial use of the platform is subject to the following conditions:
   <ul>
   <li>You may not modify any platform content.</li>
<li>You may not decompile, reverse engineer, or disassemble any content.</li>
<li>You may not alter or remove any platform content, including legal notices, disclaimers, or proprietary notices such as copyright or trademarks, unless you own or have express permission to modify them.</li>
<li>You may not use the platform or its services in any unlawful way or in any way that harms the company or any other person or entity, as determined by the company.</li>
<li>You may not reproduce, duplicate, copy, sell, resell, or exploit any part of the service, the platform, or its content without express written permission from HealthLogist.</li>
<li>You may not upload, distribute, or transmit any software or files containing viruses or harmful components that could damage the platform, its services, or any connected network, nor interfere with others' use of the platform or services.</li>
   </ul>
</li>
<li>You acknowledge that when you click a link leaving the platform, the external site is not controlled by HealthLogist, and different terms of use and privacy policies may apply. By accessing such sites, you agree that HealthLogist is not responsible for them. The company reserves the right to disable third-party links but is under no obligation to do so.</li>
<li>You agree to pay all applicable fees on time, and in case of late payments, HealthLogist reserves the right to apply interest or other charges on the outstanding amount.</li>
<li>You are responsible for confirming the technical requirements before booking a session or using any digital offerings.</li>
<li>You shall not host, display, upload, modify, publish, transmit, or share any information that:
<ul>
<li>Belongs to someone else without proper authorization.</li>
<li>Misleads or deceives the recipient about the origin of the message or communicates offensive or menacing information.</li>
<li>Threatens the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, public order, or incites the commission of any offense, or insults any nation.</li>
</ul>
</li>
</ul>
            </div>            
         </section>
      }
      <Footer />
      </ScrollPageTop>
   )
}

export default Terms