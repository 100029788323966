import React, {useState, useEffect ,useRef } from "react";
import ScrollPageTop from 'components/ui/ScrollPageTop'
import { Link, useNavigate } from "react-router-dom";
import { Tab } from '@headlessui/react'

import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from 'routes/PathLink'
import {
   API_ENDPOINT,
   APP_URL, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
} from 'components/config/Common'
import Input from 'components/ui/input.jsx'

import 'react-phone-input-2/lib/bootstrap.css'
import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'
import Label from 'components/ui/label.tsx'
import {deviceDetect} from "react-device-detect";

import Logo from "layouts/logo";

const ChooseAccountType = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [selectedIndex, setSelectedIndex] = useState(0)


   //const { login } = useAuth();
 
   //rgba(65, 188, 151,0.3) 
   return(
      <ScrollPageTop>
         <div className="flex bg-white align-center">
            <div className="w-1/2 bg-cover bg-center  min-h-screen hidden md:block" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${APP_URL}/img/userbg-reglog.jpg)`,backgroundSize:'cover',}} >
               <div className="px-4 py-3 ">
               <div className="flex w-full items-center lg:w-auto">
                    <Logo className={'ltr:ml-0 rtl:mr-0 text-white px-2 py-2 '} />
                </div>
                <h2 className="text-5xl text-white flex justify-center align-center mt-10">
                Talk to Top Therapists and Mental Health Experts
                </h2>
                <p className=" text-white flex justify-center align-center mt-10">
                  Sometimes all you need to feel better is someone who really understands. Connect with our experts for 24/7 professional help at an affordable price.
                </p>
                </div>
            </div>
            <div className="flex h-full min-h-screen w-full flex-col justify-start bg-light py-6 px-5  md:h-auto md:min-h-0 md:w-1/2 ">
                 <div className="flex w-full items-center justify-center lg:w-auto block md:hidden">
                    <Logo className={'text-accent px-2 py-2 '} />
                </div>
                <div className="flex flex-col justify-center mb-4">
                  <h1 className="font-light text-heading text-center">Let's get Started!</h1>
                  <div className="font-light txt-base text-center">Empower yourself and inspire Others to Grow Together!</div>
               </div>  
               <div className="flex justify-between md:justify-center items-start">
                  <div className="flex w-1/2 flex-col justify-center items-center ">
                     <div className="cursor-pointer w-40 md:w-52 justify-center items-center h-40 md:h-44 overflow-hidden"><Link to={Links.USER_LOGIN} className="pb-4 " target="_blank"><img src={`${APP_URL}/img/userLogin.jpg`} className="object-cover w-36 md:w-44 h-36 md:h-44 rounded-full"  /></Link></div>
                     <div className="w-full text-center text-accent font-medium text-xl mt-0 md:mt-2  ">User Login</div>
                  </div>
                  <div className="flex w-1/2  flex-col justify-center items-center  ">
                     <div className="cursor-pointer justify-center items-center w-40 md:w-52  h-40 md:h-44   "><Link to={`${Links.BECOMEANEXPERT}?selectedtb=0`} className="pb-4  " target="_blank" state={{ selectedtb: 0 }}><img src={`${APP_URL}/img/expertLogin.jpg`}  className="object-cover w-36 md:w-44 h-36 md:h-44 rounded-full" /></Link></div>
                     <div className=" w-full text-center text-accent font-medium text-xl mt-0 md:mt-2 " >Expert Login</div>
                  </div>
               </div>
            </div>
         </div>   
         <div id="recaptcha-container"></div>
      </ScrollPageTop>
   )
}

export default ChooseAccountType