
import {APP_URL, IMG_URL, amount_format,discount , truncate} from 'components/config/Common'

import { motion as ms } from 'framer-motion';
import {Links} from 'routes/PathLink'
import { Link } from "react-router-dom";
const BlogPostCard = ({
   item
}:props) => {

   return(
      <div className="bg-light drop-shadow-lg rounded-lg overflow-hidden border-2 border-accent hover:bg-accent hover:ease-in hover:duration-300">
      <Link to={item.link} target="_blank" className="group text-heading hover:text-white">
       <div className="">
         <div className="flex w-full">
            <div className="justify-center align-center w-full overflow-hidden">
                {item.img
                    ?
                    <img src={item.img} className="h-auto w-full h-[180px] object-cover ease-in duration-300 group-hover:scale-110" alt={item.title}  />
                    :
                    <img src={`${APP_URL}/img/no-image-available.png`} alt={item.title}   />
                 }
              </div>
            </div>
            </div>
              <div className="mb-0 border-gray-900 flex flex-row flex-wrap items-center justify-between gap-x-8 text-base group-hover:text-white text-heading sm:flex-col lg:flex-nowrap">
              <div className="w-full px-4 py-2 h-16  overflow-hidden">
                <span className="mb-2 block lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4 text-xl text-accent group-hover:text-white">
                 {item.title}
                </span>
             </div>
             <div className="px-4 py-2 pb-10 h-32 w-full ">{item.content.slice(0,100)}...</div>

            <div>
          </div>
        </div>
      </Link>
    </div>
   )
}

export default BlogPostCard;