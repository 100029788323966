
import {APP_URL, IMG_URL, amount_format,discount} from 'components/config/Common'

import { motion as ms } from 'framer-motion';
import {Links} from 'routes/PathLink'
import { Link } from "react-router-dom";

const supportData = [
  {
    img:'support/sad.png',
    title: 'Depression',
    desc:'Are you feeling stuck in your sadness, struggling to see a way forward? We\'re here to help.',
  },
  {
    img:'support/emotion.png',
    title: 'Social Anxiety',
    desc:'Do social situations make you feel anxious and nervous? We\'re here to help you handle them with more confidence.',
  },
  
  {
    img:'support/cigarette.png',
    title: 'Alcohol Addiction',
    desc:'Is alcohol getting in the way of living your life to the fullest? The right support is available for you.',
  },
  {
    img:'support/bipolar.png',
    title: 'Bipolar Disorder',
    desc:'Do you go through extreme highs and lows, feeling really happy one moment and deeply sad the next? We\'re here to provide the care you need.',
  },
  {
    img:'support/fear.png',
    title: 'Generalised Anxiety Disorder (GAD)',
    desc:'Do you often feel anxious, restless, or uneasy? We\'re here to help you find better ways to cope.',
  },
  {
    img:'support/ocd.png',
    title: 'Obsessive Compulsive Disorder (OCD)',
    desc:'Are unwanted thoughts causing anxiety and leading to unhelpful habits? There are ways to cope, and we can help.',
  },  
  
]




const HealthSupport = () => {

   return(
      <div className="px-10 py-16">
        <h2 className="mb-4 mt-0 text-4xl">What are you having a hard time with?</h2>
        <p>Health Logist is here to help you with all your mental health needs.</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 pt-10">
          {
            supportData && supportData.map((item, key) => (
          <div className="bg-accent text-white px-7 py-8 rounded-lg shadow-lg">
            <div className="flex w-full gap-3 items-center">
              <img src={`${IMG_URL}${item.img}`} className="w-[100px]" />
              <h2 className="my-0">{item.title}</h2>
            </div>
            <p className="py-3">{item.desc}</p>
          </div>
          ))
          }
          
        </div>
        
    </div>
   )
}



export default HealthSupport;