import React, {useState, useEffect} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import * as yup from 'yup';

import {API_ENDPOINT, APP_URL, APP_NAME, admin_phone, toast_anim} from 'components/config/Common'
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import Seo from 'components/seo/seo'
import Input from 'components/ui/input.jsx'
import TextArea from 'components/ui/text-area.jsx'
import Button from 'components/ui/Button.tsx'


import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const formSchema = yup.object().shape({
   name: yup.string().required('Name is required'), 
   email: yup.string().required('Email is required'), 
   subject: yup.string().required('Subject is required'), 
   description: yup.string().required('Description is required'), 
})
let defaultValues = {  
   name: '',
   email:'',
   subject:'',
   description:''
};

const ContactFrm = (props) => {

   const [loading, setLoading] = useState(false)
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(formSchema),
   });


   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);

   useEffect(() => {
      getPageDetails()
   },[]);

    const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/contact`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         //setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         //setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   const onSubmit = (values) => {
      //console.log('contact', values)
      setLoading(true)
      fetch(`${API_ENDPOINT}/store_contact_form`,{
			method: 'POST',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			},
         body: JSON.stringify(values)
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('mail======',result)
			
         setLoading(false)
         if(result.status === 1){
            defaultValues = {  
               name: '',
               email:'',
               subject:'',
               description:''
            };
            reset(defaultValues)	
            toast_anim('Mail sent')		
         }
		})
   }

   return(
      <ScrollPageTop>
      <div className="w-full bg-gray-100">
         <div className="flex w-full  flex-col  md:flex-row ">
           
            <div className="order-1 mb-8  w-full bg-light p-5 md:order-2 md:mb-0 md:p-8">
               <h2 className="text-2xl text-body mb-2">{pagetitle}</h2>
                     <p className="text-body mb-1" dangerouslySetInnerHTML={{__html:pagecontent}}></p>
               <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">
               Questions, Comments, Or Concerns?
               </h1>
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                     <Input                
                        label={`Name`}
                        {...register('name')}                     
                        variant="outline"
                        className="mb-5"
                        error={errors.name?.message}
                        required
                     />
                     <Input                
                        label={`Email`}
                        {...register('email')}                     
                        variant="outline"
                        className="mb-5"
                        error={errors.email?.message}
                        type="email"
                        required
                     />
                  </div>
                  <Input                
                     label={`Subject`}
                     {...register('subject')}                     
                     variant="outline"
                     className="mb-5"
                     error={errors.subject?.message}
                     required
                  />
                  <TextArea
                     label='Description'
                     {...register('description')}                        
                     variant="outline"
                     className="mb-5"
                     error={errors.description?.message}
                     required
                  />
                  <Button loading={loading}>
                     Submit
                  </Button>
               </form>
            </div>
         </div>
      </div>
      
      </ScrollPageTop>
   )
}

export default ContactFrm;